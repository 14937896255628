import React from 'react';
import { Link } from 'gatsby';

import '../assets/sass/bootstrap/bootstrap.min.css';
import '../assets/sass/app.scss';
import '../assets/sass/fontawesome/css/all.min.css';

import blueLogo from '../assets/images/blue-logo.png';

const CustomLayout = () => {
  return (
    <div className="custom-error-page-wrapper">
      <div className="error-page-logo">
      <Link to="/">
        <img src={blueLogo} className="error-img-logo" alt="" />
      </Link>
      </div>
      <div className="error-page-section">
        <div className="error-page-container">
          <div className="error-page-title">
            <span>ERROR 404</span> 
          </div>
          <div className="error-page-text">
            Oh no, that’s not supposed to happen.
          </div>
          <div className="error-page-button">
            <Link to="/">
              <button>
                <span>Return to homepage</span>
              </button>
            </Link>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomLayout;
